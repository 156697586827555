<template>
    <footer>
        <div class="linkedinBlock">
            <div class="lines">
                <Image :image="linesImage" :width="708" :height="168" :mobileHeight="195" imageClass="cover" />
            </div>
            <div class="ctaZone">
                <div class="helvetica title3">Follow us on LinkedIn</div>
                <a class="cta" href="https://de.linkedin.com/company/eic-federation" target="_blank" rel="noopener noreferer">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.959 11.7203V19.0993H15.681V12.2143C15.681 10.4843 15.062 9.30434 13.514 9.30434C12.332 9.30434 11.628 10.1003 11.319 10.8693C11.206 11.1443 11.177 11.5273 11.177 11.9123V19.0993H6.897C6.897 19.0993 6.955 7.43934 6.897 6.23034H11.177V8.05434L11.149 8.09634H11.177V8.05434C11.745 7.17934 12.76 5.92834 15.033 5.92834C17.848 5.92834 19.959 7.76834 19.959 11.7203ZM2.421 0.0273438C0.958 0.0273438 0 0.987344 0 2.25034C0 3.48534 0.93 4.47434 2.365 4.47434H2.393C3.886 4.47434 4.813 3.48534 4.813 2.25034C4.787 0.987344 3.887 0.0273438 2.422 0.0273438H2.421ZM0.254 19.0993H4.532V6.23034H0.254V19.0993Z" fill="#283380" />
                    </svg>
                    <span class="line"></span>
                    <span class="baron headerText">follow eic</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L8.5 2.56066V8.25C8.5 8.66421 8.83579 9 9.25 9C9.66421 9 10 8.66421 10 8.25V0.75C10 0.335787 9.66421 0 9.25 0H1.75C1.33579 0 1 0.335787 1 0.75C1 1.16421 1.33579 1.5 1.75 1.5H7.43934L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033Z" fill="#283380" />
                    </svg>
                </a>
            </div>
        </div>
        <div class="endBlock">
            <div class="logoZone baron headerText">
                <NuxtLink class="symbol" to="/" aria-label="EIC Homepage">
                    <svg width="252" height="68" viewBox="0 0 252 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-0.00050354 0H151.486V16.7547H-0.00050354V0Z" />
                        <path d="M-0.00050354 25.1328H151.486V41.8875H-0.00050354V25.1328Z" />
                        <path d="M-0.00050354 50.2656H151.486V67.0203H-0.00050354V50.2656Z" />
                        <path d="M160.376 0H177.828V67.0174H160.376V0Z" />
                        <path d="M251.441 21.7003C246.665 9.02218 234.428 0 220.08 0C201.573 0 186.57 15.0028 186.57 33.508C186.57 52.0145 201.573 67.0174 220.08 67.0174C234.428 67.0174 246.665 57.9952 251.441 45.3171L234.515 41.9822C231.604 46.9306 226.236 50.2627 220.08 50.2627C210.826 50.2627 203.325 42.7613 203.325 33.508C203.325 24.2561 210.826 16.7547 220.08 16.7547C226.236 16.7547 231.604 20.0868 234.515 25.0352L251.441 21.7003Z" />
                    </svg>
                </NuxtLink>
                <div class="sep"></div>
                <div class="column">
                    <span>european</span>
                    <span>international</span>
                    <span>contractors</span>
                </div>
                <div class="sep"></div>
                <div class="column italic">
                    <span>industry</span>
                    <span>federation</span>
                    <span>since 1970</span>
                </div>
            </div>
            <div class="info">
                <div class="impressum">
                    EUROPEAN INTERNATIONAL CONTRACTORS<br>
                    Kurfürstenstraße 129<br>
                    D-10785 Berlin, Germany<br>
                    <a href="mailto:info@eic-federation.eu">info@eic-federation.eu</a><br>
                    Tel: +49 30 212 86-244<br>
                    Fax: +49 30 212 86-285
                </div>
                <div class="partners" v-if="partners">
                    <div class="line"></div>
                    <div>Partners</div>
                    <div class="partnerLogos">
                        <a href="https://www.cica.net" target="_blank" rel="noopener noreferer" aria-label="CICA Homepage"><img loading="lazy" src="https://cms.eic-federation.eu/assets/906e7518-dc39-4112-80ab-20e74f28cc43?height=50&quality=70&format=webp" height="25" width="47" alt=""></a>
                        <a href="https://www.fiec.eu" target="_blank" rel="noopener noreferer" aria-label="FIEC Homepage"><img loading="lazy" src="https://cms.eic-federation.eu/assets/9a7ae3ab-0fdc-4476-9e8f-5070a458d350?height=60&quality=70&format=webp" height="30" width="95" alt=""></a>
                    </div>
                </div>
                <div class="copy">
                    <span>&copy; EIC Federation {{ new Date().getFullYear() }}</span>
                    <!-- <a href="/privacy-policy">Privacy Policy</a> -->
                    <NuxtLink to="/policies/privacy-policy">Privacy Policy</NuxtLink>
                    <NuxtLink to="/policies/legal-notice">Legal Notice & Disclaimer</NuxtLink>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
const linesImage = ref({
    id: 'fa413316-4cf7-4494-9ba2-a4e5e707a1ae',
    width: 1416,
    height: 336,
    title: '',
})

withDefaults(defineProps<{ partners?: boolean }>(), {
    partners: false
})
</script>

<style lang="scss" scoped>
footer {
    padding-top: var(--size-gutter-sections);
    padding-bottom: var(--size-gutter-large);

    .linkedinBlock {
        margin: 0 var(--size-margin-page);
        background-color: var(--color-secondary-light);

        height: 168px;

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: var(--size-margin-page);

        .lines {
            position: relative;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .ctaZone {
            padding: 0 var(--size-gutter-double);
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--size-gutter-default);
            color: var(--color-primary);

            .title3 {
                text-wrap: balance;
            }

            .cta {
                border: 1px solid var(--color-primary);
                padding: 12px 16px;

                color: var(--color-primary);
                text-decoration: none;

                display: flex;
                align-items: center;
                gap: 12px;

                transition: background-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);

                .baron {
                    display: block;
                    margin-top: 5px;
                    text-wrap: nowrap;

                    font-size: 14px;
                    transition: color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                }

                .line {
                    display: block;
                    width: 1px;
                    height: 24px;
                    background-color: var(--color-primary);
                    transition: background-color 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                }

                svg {
                    path {
                        fill: var(--color-primary);
                        transition: fill 300ms 0ms cubic-bezier(.4, 0, .2, 1);
                    }
                }

                &:hover {
                    background-color: var(--color-primary);
                    color: white;

                    .line {
                        background-color: white;
                    }

                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
    }

    .endBlock {
        border-top: 0.5px solid var(--color-primary);
        padding: 0 var(--size-margin-page);

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: var(--size-margin-page);

        .logoZone {
            display: flex;
            align-items: flex-start;
            gap: 24px;

            color: var(--color-primary);

            .symbol {
                display: block;
                padding: var(--size-margin-page) 0 0 0;

                svg {
                    path {
                        fill: var(--color-primary);
                    }
                }
            }

            .column {
                display: flex;
                flex-direction: column;
                gap: 4px;
                padding-top: 21px;

                &.italic {
                    font-style: italic;
                }
            }
        }

        .info {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: var(--size-gutter-small);

            .impressum {
                padding-top: 21px;
                font-size: var(--text-size-bodys);
                line-height: var(--text-line-bodys);
                color: var(--color-text-light);
            }

            .copy {
                display: flex;
                gap: var(--size-gutter-default);
                font-size: var(--text-size-bodyxs);
                line-height: var(--text-line-bodyxs);
                color: var(--color-text-light);
            }

            .partners {
                position: absolute;
                padding-top: 21px;
                padding-left: 22px;
                font-size: var(--text-size-bodys);
                line-height: var(--text-line-bodys);
                color: var(--color-text-light);

                left: 290px;

                .line {
                    height: 27px;
                    border-right: 0.5px solid var(--color-primary);
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .partnerLogos {
                    margin-top: 4px;
                    display: flex;
                    gap: 10px;
                    align-items: flex-start;
                }
            }

            a {
                color: currentColor;
                text-decoration: none
            }
        }

        .sep {
            height: 27px;
            border-right: 0.5px solid var(--color-primary);
        }
    }
}

@media screen and (max-width: 1200px) {
    footer {

        .endBlock {
            display: flex;
            flex-direction: column;

            .logoZone {
                .symbol {
                    padding-left: 0;
                }
            }

            .info {
                .partners {
                    position: relative;
                    left: auto;
                    top: auto;
                    padding: 12px 0 0 0;

                    .line {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    footer {
        .linkedinBlock {
            margin: 0;
            height: auto;
            display: flex;
            flex-direction: column;

            .lines {
                height: 170px;
            }

            .ctaZone {
                height: 200px;
                padding: 0 var(--size-margin-page);
                flex-direction: column;
                gap: var(--size-gutter-default);
                align-items: flex-start;
                justify-content: center;
            }
        }

        .endBlock {
            display: flex;
            flex-direction: column;

            .logoZone {
                .symbol {
                    padding-left: 0;
                }

                .column,
                .sep {
                    display: none;
                }
            }

            .info {
                .partners {
                    position: relative;
                    left: auto;
                    top: auto;
                    padding: 12px 0 0 0;

                    .line {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>